<template>
  <section id="testimonials" class="py-16 px-4 bg-gray-800">
    <div class="max-w-7xl mx-auto">
      <h2 class="text-3xl md:text-4xl font-bold text-white text-center mb-12">What Our Users Say</h2>
      
      <div class="grid md:grid-cols-2 gap-8">
        <div class="bg-gray-700 p-8 rounded-lg">
          <p class="text-gray-300 text-lg italic mb-6">
            "Since using CarbonLedger, I've cut my monthly bills by 30%—just by seeing where I was wasting energy."
          </p>
          <div class="flex items-center">
            <div class="ml-4">
              <p class="text-white font-bold">Alex</p>
              <p class="text-gray-400">Homeowner</p>
            </div>
          </div>
        </div>
        
        <div class="bg-gray-700 p-8 rounded-lg">
          <p class="text-gray-300 text-lg italic mb-6">
            "The real-time tracking and personalized recommendations have transformed how we manage our energy use."
          </p>
          <div class="flex items-center">
            <div class="ml-4">
              <p class="text-white font-bold">Sarah</p>
              <p class="text-gray-400">Business Owner</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template> 