<template>
  <section id="value-prop" class="py-16 px-4">
    <div class="max-w-7xl mx-auto">
      <h2 class="section-title">Track Energy, Unlock Value</h2>
      <p class="section-subtitle">
        Get precise energy tracking that pays for itself. See exactly what you use, 
        eliminate waste, and maximize your energy investments.
      </p>

      <div class="grid md:grid-cols-3 gap-8">
        <div class="feature-card">
          <div class="feature-icon"><i class="fas fa-chart-line"></i></div>
          <h3 class="feature-title">99.9% Accurate Tracking</h3>
          <p class="feature-description">
            Advanced AI and IoT technology delivers precision insights into your energy consumption 
            and generation patterns.
          </p>
        </div>

        <div class="feature-card">
          <div class="feature-icon"><i class="fas fa-coins"></i></div>
          <h3 class="feature-title">Maximize ROI</h3>
          <p class="feature-description">
            Identify cost-saving opportunities and optimize your energy assets for 
            maximum financial return.
          </p>
        </div>

        <div class="feature-card">
          <div class="feature-icon"><i class="fas fa-leaf"></i></div>
          <h3 class="feature-title">Sustainable Future</h3>
          <p class="feature-description">
            Make data-driven decisions that reduce your carbon footprint while 
            improving your bottom line.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.about-section {
  background: var(--background-primary);
  padding: 5rem 0;
}

.feature-card {
  background: var(--background-card);
  border-radius: 12px;
  padding: 2rem;
  transition: all 0.3s ease;
  border: 1px solid hsla(210, 20%, 98%, 0.1);
}

.feature-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 24px hsla(215, 25%, 12%, 0.2);
  border-color: var(--color-primary);
}

.feature-icon {
  color: var(--color-primary);
  font-size: 2rem;
  margin-bottom: 1rem;
}

.feature-title {
  color: var(--text-secondary);
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.feature-description {
  color: var(--text-secondary);
  opacity: 0.85;
  line-height: 1.6;
}

.section-title {
  color: var(--text-primary);
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 3rem;
}

.section-subtitle {
  color: var(--text-secondary);
  text-align: center;
  max-width: 600px;
  margin: 0 auto 4rem;
  line-height: 1.6;
}
</style>
