<template>
  <div id="app" class="bg-[#1A202C] min-h-screen">
    <NavBar />
    <main>
      <HeroSection />
      <AboutSection id="about" />
      <FeaturesSection />
      <TestimonialsSection />
      <EnergyChart />
      <FaqSection />
    </main>
    <Footer />
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';
import HeroSection from './components/HeroSection.vue';
import AboutSection from './components/AboutSection.vue';
import FeaturesSection from './components/FeaturesSection.vue';
import TestimonialsSection from './components/TestimonialsSection.vue';
import EnergyChart from './components/EnergyChart.vue';
import FaqSection from './components/FaqSection.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    NavBar,
    HeroSection,
    AboutSection,
    FeaturesSection,
    TestimonialsSection,
    EnergyChart,
    FaqSection,
    Footer,
  },
  mounted() {
    // Add smooth scrolling behavior
    document.documentElement.style.scrollBehavior = 'smooth';
  }
};
</script>

<style>
:root {
  /* Primary Colors */
  --primary-green: #10B981;
  --primary-dark: #1A202C;
  
  /* Accent Colors */
  --accent-green-light: #34D399;
  --accent-green-dark: #059669;
  
  /* Background Colors */
  --bg-dark: #1A202C;
  --bg-darker: #2D3748;
  --bg-card: #2D3748;
  
  /* Text Colors */
  --text-white: #F9FAFB;
  --text-gray-light: #E2E8F0;
  --text-gray: #A0AEC0;
}

body {
  margin: 0;
  padding: 0;
  background: var(--bg-dark);
  color: var(--text-white);
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}

section {
  width: 100%;
}

html {
  scroll-behavior: smooth;
}
</style> 