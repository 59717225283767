<template>
  <footer>
    <p>© {{ new Date().getFullYear() }} Energy Insights</p>
  </footer>
</template>

<script>
export default {}
</script>

<style scoped>
footer {
  text-align: center;
  margin-top: 2rem;
  color: #666;
  font-size: 0.9rem;
}
</style>
