<template>
  <nav aria-label="Main navigation" class="bg-[#1A202C] p-4 fixed w-full top-0 z-50">
    <div class="max-w-7xl mx-auto flex justify-between items-center">
      <img src="../assets/logo.svg" alt="CarbonLedger" class="h-8" />
      <ul class="flex space-x-6 text-gray-300">
        <li><a href="#about" class="hover:text-green-500 transition">About</a></li>
        <li><a href="#features" class="hover:text-green-500 transition">Features</a></li>
        <li><a href="#testimonials" class="hover:text-green-500 transition">Testimonials</a></li>
        <li><a href="#faq" class="hover:text-green-500 transition">FAQ</a></li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  props: ['brandName']
}
</script>

<style scoped>
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
