<template>
  <section class="py-16 px-4">
    <div class="max-w-7xl mx-auto">
      <div class="text-center mb-12">
        <h2 class="text-3xl font-bold text-white mb-4">Live Energy Insights</h2>
        <p class="text-gray-400 max-w-2xl mx-auto">
          Track real-time energy usage, carbon intensity, and renewable energy generation across the UK.
          Data provided by the National Grid ESO.
        </p>
      </div>

      <div class="bg-gray-800 rounded-lg p-6 shadow-lg">
        <div class="mb-6 flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
          <div>
            <h3 class="text-xl font-semibold text-white mb-1">UK Energy Usage</h3>
            <p class="text-gray-400 text-sm">Last updated: {{ lastUpdated }}</p>
          </div>
          <div class="flex flex-col md:flex-row gap-4 w-full md:w-auto">
            <select 
              v-model="selectedRegion" 
              class="bg-gray-700 text-white px-4 py-2 rounded-lg border border-gray-600 focus:outline-none focus:border-green-500 w-full md:w-auto"
            >
              <option value="">All Regions</option>
              <option v-for="region in regions" :key="region" :value="region">
                {{ region }}
              </option>
            </select>
            <select 
              v-model="timeRange" 
              class="bg-gray-700 text-white px-4 py-2 rounded-lg border border-gray-600 focus:outline-none focus:border-green-500 w-full md:w-auto"
            >
              <option value="12">Last 12 Hours</option>
              <option value="24">Last 24 Hours</option>
              <option value="48">Last 48 Hours</option>
              <option value="72">Last 72 Hours</option>
            </select>
          </div>
        </div>

        <div v-if="loading" class="flex justify-center items-center h-[400px]">
          <div class="flex flex-col items-center gap-4">
            <div class="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-green-500"></div>
            <p class="text-gray-400">Loading energy data...</p>
          </div>
        </div>

        <div v-else-if="error" class="flex justify-center items-center h-[400px]">
          <div class="text-center">
            <p class="text-red-500 text-lg mb-2">{{ error }}</p>
            <button 
              @click="fetchData" 
              class="bg-gray-700 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition"
            >
              Try Again
            </button>
          </div>
        </div>

        <div v-else>
          <div class="h-[400px] mb-8">
            <Line
              :data="chartData"
              :options="chartOptions"
            />
          </div>

          <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div class="bg-gray-700 p-4 rounded-lg">
              <h4 class="text-gray-400 text-sm mb-1">
                Average Demand ({{ getTimeRangeText }})
              </h4>
              <p class="text-white text-2xl font-semibold">{{ averageDemand }} MW</p>
              <p class="text-gray-400 text-sm">Peak: {{ peakDemand }} MW</p>
            </div>
            <div class="bg-gray-700 p-4 rounded-lg">
              <h4 class="text-gray-400 text-sm mb-1">
                Carbon Intensity ({{ getTimeRangeText }})
              </h4>
              <p class="text-white text-2xl font-semibold">{{ averageIntensity }} gCO2/kWh</p>
              <p class="text-sm" :class="carbonIntensityColor">{{ carbonIntensityStatus }}</p>
            </div>
            <div class="bg-gray-700 p-4 rounded-lg">
              <h4 class="text-gray-400 text-sm mb-1">
                Renewable Energy ({{ getTimeRangeText }})
              </h4>
              <p class="text-white text-2xl font-semibold">{{ averageRenewable }}%</p>
              <p class="text-sm" :class="renewableColor">{{ renewableStatus }}</p>
            </div>
            <div class="bg-gray-700 p-4 rounded-lg">
              <h4 class="text-gray-400 text-sm mb-1">
                Total Generation ({{ getTimeRangeText }})
              </h4>
              <p class="text-white text-2xl font-semibold">{{ totalGeneration }} MW</p>
              <p class="text-gray-400 text-sm">Surplus: {{ surplusGeneration }} MW</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, onMounted, watch, computed, onUnmounted } from 'vue';
import { Line } from 'vue-chartjs';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { getLatestEnergyData, getEnergyHistory } from '../services/energyService';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default {
  components: { Line },
  setup() {
    const loading = ref(false);
    const error = ref(null);
    const selectedRegion = ref('London'); // Default to London
    const timeRange = ref('12');
    const energyData = ref([]);
    const regions = ref([
      'London', 'South East', 'South West', 'East of England',
      'West Midlands', 'East Midlands', 'Yorkshire', 'North West',
      'North East', 'Wales', 'Scotland', 'Northern Ireland'
    ]);

    const lastUpdated = computed(() => {
      if (energyData.value.length === 0) return 'Loading...';
      const latest = new Date(energyData.value[0].timestamp);
      return latest.toLocaleString();
    });

    const formatTimeLabel = (timestamp, range) => {
      const date = new Date(timestamp);
      const hours = range <= 24;
      
      if (hours) {
        // For 12-24 hours, show hours only
        return date.toLocaleTimeString([], { hour: '2-digit' });
      }
      // For 48-72 hours, show date and hour
      return date.toLocaleString([], {
        month: 'short',
        day: 'numeric',
        hour: '2-digit'
      });
    };

    const chartData = computed(() => ({
      labels: energyData.value.map(d => formatTimeLabel(d.timestamp, parseInt(timeRange.value))),
      datasets: [
        {
          label: 'Demand (MW)',
          data: energyData.value.map(d => d.demand_actual),
          borderColor: '#10B981',
          backgroundColor: 'rgba(16, 185, 129, 0.1)',
          tension: 0.4,
          fill: true
        },
        {
          label: 'Carbon Intensity (gCO2/kWh)',
          data: energyData.value.map(d => d.carbon_intensity),
          borderColor: '#EF4444',
          backgroundColor: 'rgba(239, 68, 68, 0.1)',
          tension: 0.4,
          fill: true,
          yAxisID: 'carbonIntensity'
        },
        {
          label: 'Renewable %',
          data: energyData.value.map(d => d.renewable_percentage),
          borderColor: '#3B82F6',
          backgroundColor: 'rgba(59, 130, 246, 0.1)',
          tension: 0.4,
          fill: true,
          yAxisID: 'percentage'
        }
      ]
    }));

    const getTicksConfig = (range) => {
      const rangeNum = parseInt(range);
      if (rangeNum <= 12) {
        return {
          maxTicksLimit: 12, // Show hourly intervals
          stepSize: 60
        };
      } else if (rangeNum <= 24) {
        return {
          maxTicksLimit: 12 // Show every other hour
        };
      }
      return {
        maxTicksLimit: 24 // Show fewer points for longer periods
      };
    };

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        intersect: false,
        mode: 'index'
      },
      scales: {
        y: {
          beginAtZero: true,
          grid: {
            color: 'rgba(255, 255, 255, 0.1)'
          },
          ticks: {
            color: 'rgba(255, 255, 255, 0.7)',
            callback: value => `${value} MW`
          },
          title: {
            display: true,
            text: 'Demand (MW)',
            color: 'rgba(255, 255, 255, 0.7)'
          }
        },
        carbonIntensity: {
          position: 'right',
          beginAtZero: true,
          grid: {
            display: false
          },
          ticks: {
            color: 'rgba(255, 255, 255, 0.7)',
            callback: value => `${value} gCO2`
          },
          title: {
            display: true,
            text: 'Carbon Intensity',
            color: 'rgba(255, 255, 255, 0.7)'
          }
        },
        percentage: {
          position: 'right',
          beginAtZero: true,
          max: 100,
          grid: {
            display: false
          },
          ticks: {
            color: 'rgba(255, 255, 255, 0.7)',
            callback: value => `${value}%`
          },
          title: {
            display: true,
            text: 'Renewable %',
            color: 'rgba(255, 255, 255, 0.7)'
          }
        },
        x: {
          grid: {
            color: 'rgba(255, 255, 255, 0.1)'
          },
          ticks: {
            color: 'rgba(255, 255, 255, 0.7)',
            maxRotation: 45,
            minRotation: 45,
            autoSkip: true,
            ...getTicksConfig(timeRange.value),
            callback: function(val, index) {
              const range = parseInt(timeRange.value);
              const label = this.getLabelForValue(val);
              
              if (range <= 1) {
                // For 1 hour, show every other label
                return index % 2 === 0 ? label : '';
              } else if (range <= 6) {
                // For 6 hours, show every other label
                return index % 2 === 0 ? label : '';
              } else if (range <= 12) {
                // For 12 hours, show every third label
                return index % 3 === 0 ? label : '';
              } else if (range <= 24) {
                // For 24 hours, show every other label
                return index % 2 === 0 ? label : '';
              }
              // For longer periods, show every fourth label
              return index % 4 === 0 ? label : '';
            }
          },
          title: {
            display: true,
            text: timeRange.value === '1' ? 'Time (Last Hour)' :
                  timeRange.value === '6' ? 'Time (Last 6 Hours)' :
                  timeRange.value === '12' ? 'Time (Last 12 Hours)' :
                  timeRange.value === '24' ? 'Time (Last 24 Hours)' :
                  timeRange.value === '48' ? 'Time (Last 48 Hours)' :
                  'Time (Last 72 Hours)',
            color: 'rgba(255, 255, 255, 0.7)',
            padding: { top: 10 }
          }
        }
      },
      plugins: {
        legend: {
          labels: {
            color: 'rgba(255, 255, 255, 0.7)',
            usePointStyle: true,
            pointStyle: 'circle'
          }
        },
        tooltip: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          titleColor: 'rgba(255, 255, 255, 0.9)',
          bodyColor: 'rgba(255, 255, 255, 0.9)',
          padding: 12,
          displayColors: true
        }
      }
    };

    // Computed properties for statistics
    const averageDemand = computed(() => {
      if (energyData.value.length === 0) return 0;
      const avg = energyData.value.reduce((sum, d) => sum + d.demand_actual, 0) / energyData.value.length;
      return Math.round(avg);
    });

    const peakDemand = computed(() => {
      if (energyData.value.length === 0) return 0;
      return Math.round(Math.max(...energyData.value.map(d => d.demand_actual)));
    });

    const averageIntensity = computed(() => {
      if (energyData.value.length === 0) return 0;
      const avg = energyData.value.reduce((sum, d) => sum + d.carbon_intensity, 0) / energyData.value.length;
      return Math.round(avg);
    });

    const carbonIntensityStatus = computed(() => {
      const intensity = averageIntensity.value;
      if (intensity < 200) return 'Low Carbon';
      if (intensity < 300) return 'Moderate';
      return 'High Carbon';
    });

    const carbonIntensityColor = computed(() => {
      const intensity = averageIntensity.value;
      if (intensity < 200) return 'text-green-400';
      if (intensity < 300) return 'text-yellow-400';
      return 'text-red-400';
    });

    const averageRenewable = computed(() => {
      if (energyData.value.length === 0) return 0;
      const avg = energyData.value.reduce((sum, d) => sum + d.renewable_percentage, 0) / energyData.value.length;
      return Math.round(avg);
    });

    const renewableStatus = computed(() => {
      const percentage = averageRenewable.value;
      if (percentage > 50) return 'High Renewable';
      if (percentage > 30) return 'Moderate';
      return 'Low Renewable';
    });

    const renewableColor = computed(() => {
      const percentage = averageRenewable.value;
      if (percentage > 50) return 'text-green-400';
      if (percentage > 30) return 'text-yellow-400';
      return 'text-red-400';
    });

    const totalGeneration = computed(() => {
      if (energyData.value.length === 0) return 0;
      const avg = energyData.value.reduce((sum, d) => sum + d.generation_mw, 0) / energyData.value.length;
      return Math.round(avg);
    });

    const surplusGeneration = computed(() => {
      return Math.round(totalGeneration.value - averageDemand.value);
    });

    const getTimeRangeText = computed(() => {
      switch (timeRange.value) {
        case '12':
          return 'Last 12 Hours';
        case '24':
          return 'Last 24 Hours';
        case '48':
          return 'Last 48 Hours';
        case '72':
          return 'Last 72 Hours';
        default:
          return 'Last 12 Hours';
      }
    });

    async function fetchData() {
      loading.value = true;
      error.value = null;
      try {
        if (selectedRegion.value) {
          try {
            const data = await getEnergyHistory(selectedRegion.value, timeRange.value);
            if (data && data.length > 0) {
              energyData.value = data.reverse();
            } else {
              // Use mock data if API fails or returns empty data
              energyData.value = generateMockData();
            }
          } catch (e) {
            console.warn('Failed to fetch real data, using mock data:', e);
            energyData.value = generateMockData();
          }
        } else {
          try {
            const data = await getLatestEnergyData();
            if (data && data.length > 0) {
              energyData.value = data;
            } else {
              energyData.value = generateMockData();
            }
          } catch (e) {
            console.warn('Failed to fetch latest data, using mock data:', e);
            energyData.value = generateMockData();
          }
        }
      } catch (e) {
        console.error('Error fetching data:', e);
        error.value = 'Unable to load energy data. Using sample data instead.';
        energyData.value = generateMockData();
      } finally {
        loading.value = false;
      }
    }

    // Helper function to generate mock data
    function generateMockData() {
      const mockData = [];
      const now = new Date();
      const hours = parseInt(timeRange.value);
      
      for (let i = hours; i >= 0; i--) {
        const timestamp = new Date(now - i * 60 * 60 * 1000);
        mockData.push({
          timestamp: timestamp.toISOString(),
          demand_actual: Math.floor(30000 + Math.random() * 10000),
          carbon_intensity: Math.floor(150 + Math.random() * 100),
          renewable_percentage: Math.floor(30 + Math.random() * 40),
          generation_mw: Math.floor(32000 + Math.random() * 10000)
        });
      }
      
      return mockData;
    }

    // Auto-refresh data every 5 minutes
    let refreshInterval;
    onMounted(() => {
      fetchData();
      refreshInterval = setInterval(fetchData, 5 * 60 * 1000);
    });

    // Clean up interval on component unmount
    onUnmounted(() => {
      if (refreshInterval) clearInterval(refreshInterval);
    });

    watch([selectedRegion, timeRange], () => {
      fetchData();
    });

    return {
      loading,
      error,
      selectedRegion,
      timeRange,
      regions,
      chartData,
      chartOptions,
      lastUpdated,
      averageDemand,
      peakDemand,
      averageIntensity,
      carbonIntensityStatus,
      carbonIntensityColor,
      averageRenewable,
      renewableStatus,
      renewableColor,
      totalGeneration,
      surplusGeneration,
      fetchData,
      getTimeRangeText
    };
  }
};
</script>

<style scoped>
canvas {
  width: 100%;
  height: 400px;
}
</style>
