<template>
  <section class="hero-section py-16 px-4">
    <div class="max-w-3xl mx-auto text-center">
      <p class="hero-text mb-8">
        We're more than just a tracking platform. We provide real-time, transparent data
        and tools that reveal exactly where your energy goes, helping you make smarter
        decisions and accelerate your journey toward sustainability.
      </p>
      
      <div class="flex justify-center gap-4">
        <button 
          class="btn-secondary"
          type="button"
          @click="toggleEmailForm"
        >
          Learn More
        </button>
      </div>

      <transition name="fade">
        <div v-show="showEmailForm" class="email-form-container mt-8">
          <form @submit.prevent="submitEmail" class="email-form">
            <input 
              type="email" 
              v-model="email" 
              placeholder="Enter your email"
              class="email-input"
              required
            />
            <button type="submit" class="submit-btn">
              Keep Me Updated
            </button>
          </form>
          <p v-if="submitStatus" class="status-message" :class="{ 
            'success': !submitStatus.includes('wrong'),
            'error': submitStatus.includes('wrong')
          }">
            {{ submitStatus }}
          </p>
        </div>
      </transition>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HeroSection',
  data() {
    return {
      showEmailForm: false,
      email: '',
      submitStatus: '',
      isSubmitting: false
    }
  },
  methods: {
    toggleEmailForm() {
      this.showEmailForm = !this.showEmailForm;
    },
    async submitEmail() {
      if (this.isSubmitting) return;
      
      this.isSubmitting = true;
      try {
        console.log('Submitting email:', this.email);
        
        const response = await fetch('/api/storeEmail', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: this.email,
            timestamp: new Date().toISOString()
          })
        });

        console.log('Response status:', response.status);
        
        if (!response.ok) {
          const errorText = await response.text();
          console.error('Response error:', errorText);
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        console.log('Response data:', data);
        
        if (data.success) {
          this.submitStatus = 'Thanks for your interest! We\'ll keep you updated.';
          this.email = '';
          setTimeout(() => {
            this.showEmailForm = false;
            this.submitStatus = '';
          }, 2000);
        } else {
          throw new Error(data.error || 'Failed to submit');
        }
      } catch (error) {
        console.error('Failed to store email:', error);
        this.submitStatus = 'Something went wrong. Please try again.';
      } finally {
        this.isSubmitting = false;
      }
    }
  }
}
</script>

<style scoped>
.hero-section {
  background: var(--background-primary);
  padding: 5rem 0;
}

.hero-text {
  color: var(--text-secondary);
  font-size: 1.25rem;
  line-height: 1.6;
}

.btn-primary {
  background: var(--accent-success);
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 600;
  transition: all 0.2s ease;
}

.btn-primary:hover {
  filter: brightness(110%);
  transform: translateY(-1px);
}

.btn-secondary {
  background: transparent;
  color: var(--accent-success);
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 600;
  border: 2px solid var(--accent-success);
  transition: all 0.2s ease;
}

.btn-secondary:hover {
  background: hsla(150, 85%, 40%, 0.1);
  transform: translateY(-1px);
}

.email-form-container {
  max-width: 400px;
  margin: 0 auto;
}

.email-form {
  display: flex;
  gap: 0.5rem;
  background: var(--background-card);
  padding: 0.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px hsla(215, 25%, 12%, 0.1);
}

.email-input {
  flex-grow: 1;
  padding: 0.5rem 1rem;
  border: 1px solid hsla(210, 20%, 98%, 0.1);
  border-radius: 4px;
  background: transparent;
  color: var(--text-secondary);
}

.email-input::placeholder {
  color: var(--text-secondary);
  opacity: 0.6;
}

.submit-btn {
  background: var(--accent-success);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-weight: 600;
  transition: all 0.2s ease;
}

.submit-btn:hover {
  filter: brightness(110%);
  transform: translateY(-1px);
}

/* Add transition styles */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.status-message {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  text-align: center;
}

.status-message.success {
  color: var(--accent-success);
}

.status-message.error {
  color: var(--accent-error);
}
</style> 