const API_URL = process.env.NODE_ENV === 'development' 
  ? 'http://127.0.0.1:8787'
  : 'https://carbonledger-api.codegood.workers.dev';

export async function getLatestEnergyData() {
  const response = await fetch(`${API_URL}/api/energy/latest`);
  if (!response.ok) {
    throw new Error('Failed to fetch latest energy data');
  }
  return response.json();
}

export async function getEnergyHistory(region, hours = 24) {
  const response = await fetch(
    `${API_URL}/api/energy/history?region=${encodeURIComponent(region)}&hours=${hours}`
  );
  if (!response.ok) {
    throw new Error('Failed to fetch energy history');
  }
  return response.json();
} 