<template>
  <section id="faq" class="py-16 px-4" style="background: var(--background-primary)">
    <div class="max-w-3xl mx-auto">
      <h2 class="section-title">Frequently Asked Questions</h2>
      
      <div class="space-y-6">
        <div v-for="(faq, index) in faqs" 
             :key="index" 
             class="faq-item">
          <h3 class="faq-title">{{ faq.question }}</h3>
          <p class="faq-answer">{{ faq.answer }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FaqSection',
  data() {
    return {
      faqs: [
        {
          question: "How quickly can I see results?",
          answer: "Immediately! Our platform provides real-time tracking with 99.9% accuracy from day one. You'll see exactly what you consume and export, helping you make informed decisions right away."
        },
        {
          question: "Do I need special hardware?",
          answer: "No additional hardware needed. We work seamlessly with your existing smart meters and devices. If you don't have smart meters, we can provide them - just ask!"
        },
        {
          question: "What's the return on investment?",
          answer: "Most clients see ROI within months. Start with our free 14-day trial to see the potential savings for your organization. No commitment required!"
        }
      ]
    }
  }
}
</script>

<style scoped>
.faq-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--text-secondary);
  margin-bottom: 1rem;
  position: relative;
  padding-bottom: 0.75rem;
}

.faq-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 3rem;
  height: 2px;
  background: var(--color-primary);
  transition: width 0.3s ease;
}

.faq-item {
  background: var(--background-card);
  padding: 1.75rem;
  border-radius: 12px;
  margin-bottom: 1rem;
  box-shadow: 0 4px 6px hsla(215, 25%, 12%, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.faq-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px hsla(215, 25%, 12%, 0.15);
}

.faq-answer {
  color: var(--text-secondary);
  line-height: 1.6;
  opacity: 0.85;
}

.section-title {
  color: var(--text-primary);
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 3rem;
}

.space-y-6 > * + * {
  margin-top: 1.5rem;
}
</style> 
