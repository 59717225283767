<template>
  <section id="features" class="py-16 px-4 bg-gray-900">
    <div class="max-w-7xl mx-auto">
      <h2 class="text-3xl md:text-4xl font-bold text-white text-center mb-12">Features</h2>
      
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div class="bg-gray-800 p-6 rounded-lg hover:bg-gray-700 transition">
          <div class="text-green-500 text-3xl mb-4">
            <i class="fas fa-chart-line"></i>
          </div>
          <h3 class="text-white text-xl font-bold mb-3">Intelligent Visualisation</h3>
          <p class="text-gray-300">See your energy data come to life with real-time graphs and trends.</p>
        </div>
        
        <div class="bg-gray-800 p-6 rounded-lg hover:bg-gray-700 transition">
          <div class="text-green-500 text-3xl mb-4">
            <i class="fas fa-bell"></i>
          </div>
          <h3 class="text-white text-xl font-bold mb-3">Custom Alerts</h3>
          <p class="text-gray-300">Get instant updates on unusual activity or opportunities to optimise your consumption.</p>
        </div>
        
        <div class="bg-gray-800 p-6 rounded-lg hover:bg-gray-700 transition">
          <div class="text-green-500 text-3xl mb-4">
            <i class="fas fa-lightbulb"></i>
          </div>
          <h3 class="text-white text-xl font-bold mb-3">Expert Recommendations</h3>
          <p class="text-gray-300">Personalised guidance to help you adopt cleaner energy practices and reduce emissions.</p>
        </div>
      </div>
    </div>
  </section>
</template> 
